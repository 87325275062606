html, body, #root {
  height: 100%;
  minWidth: 320px;
}

/* react-image-gallery css overrides */
.image-gallery-icon  {
  visibility: hidden;
}

.image-gallery {
  width: 100%;
  height: auto;
}

.image-gallery:hover .image-gallery-icon {
  visibility: visible;
}

.image-gallery-slide img {
  width: 100%;
  height: auto;
  max-height: 80vh;
  object-fit: cover;
  overflow: hidden;
  object-position: center center;
}

.fullscreen .image-gallery-slide img {
  max-height: 100vh;
}

.image-gallery-content .image-gallery-slide img.image-gallery-image {
  max-height: 80vh;
}
